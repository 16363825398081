<template>
  <Cancelled />
</template>

<script>
import Cancelled from "@/components/cancelled/Cancelled.vue";

export default {
  name: "cancelled",
  components: {
    Cancelled,
  },
};
</script>
